<template>
  <b-container class="mt-4">
    <b-spinner v-if="loading"></b-spinner>
    <div v-else>
      <b-row>
        <b-col>
          <h4>Test member eligibility</h4>
          <small>Testing for client: <b>{{ selectedClient.display_name }}</b></small>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col col md="4">
          <b-form-group label="Selected client" description="The eligibility test will simulate an eligibility attempt in the selected client">
            <clients-form-select v-model="selectedClientId" :skip-disabled="true"/>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-card title="Member data">
            <b-row>
              <b-col cols="6">
                <b-form-group label="First name" label-for="first_name">
                  <b-form-input id="first_name" v-model="memberInfo.first_name"/>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Last name" label-for="first_name">
                  <b-form-input id="last_name" v-model="memberInfo.last_name"/>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Date of birth" description="Format: YYYYMMDD" label-for="dob">
                  <b-form-input id="dob"
                                min="1900-01-01"
                                v-model="memberInfo.dob"/>
                </b-form-group>
              </b-col>
            </b-row>

            <hr class="mt-4"/>
            <b-row class="mt-4">
              <b-col cols="6">
                <b-form-group label="Email" label-for="email">
                  <b-form-input id="email" v-model="memberInfo.email"/>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Member uuid" label-for="member_uuid">
                  <b-form-input id="member_uuid" v-model="memberInfo.uuid"/>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="ID Type" label-for="id_type">
                  <b-form-input id="id_type" v-model="memberInfo.id_type"/>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Member ID" label-for="member_id">
                  <b-form-input id="member_id" v-model="memberInfo.identification"/>
                </b-form-group>
              </b-col>
            </b-row>


            <b-row class="mt-4">
              <b-col cols="6">
                <b-form-group label="Subscriber First name" label-for="first_name">
                  <b-form-input id="subscriber_first_name" v-model="subscriberInfo.first_name"/>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Subscriber Last name" label-for="subscriber_first_name">
                  <b-form-input id="subscriber_last_name" v-model="subscriberInfo.last_name"/>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Subscriber Date of birth" description="Format: YYYYMMDD" label-for="subscriber_dob">
                  <b-form-input id="subscriber_dob"
                                min="1900-01-01"
                                v-model="subscriberInfo.dob"/>
                </b-form-group>
              </b-col>
            </b-row>


            <b-row class="mt-4">
              <b-col cols="6">
                <b-form-group label="Payer key" label-for="payer_key">
                  <b-form-input id="payer_key" v-model="payerInfo.key"/>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Payer name" label-for="payer_name">
                  <b-form-input id="payer_name" v-model="payerInfo.name"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card title="Eligibility details">
            <b-row v-if="!showEligibilityResponse">
              <b-col cols="12">
                Eligibility data will be shown here.
              </b-col>
            </b-row>

            <b-row v-else>
              <b-col cols="12">
                <b-badge variant="success" v-if="eligibilityResponse.eligible">Member is Eligible</b-badge>
                <b-badge variant="danger" v-else>Member not Eligible</b-badge>
              </b-col>
              <b-col cols="12">
                <pre class="mt-1 mb-0">{{ eligibilityResponse | pretty }}</pre>
              </b-col>
            </b-row>
          </b-card>

          <b-col cols="12" class="mt-3 pl-0">
            <b-button type="button" :disabled="!hasAllRequiredInfo || testing"
                      @click="runMemberEligibilityTest" variant="primary">
              <b-spinner label="Loading..." variant="light" small v-if="testing"></b-spinner>
              <span v-else> Send test </span>
            </b-button>
          </b-col>

        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { clientToggleOptions } from '@/scripts/definitions';
import ClientsFormSelect from '@/components/Inputs/ClientsFormSelect.vue';

export default {
  components: { ClientsFormSelect },
  name: 'EligibilityTest',
  data() {
    return {
      selectedClientId: null,
      loading: false,
      testing: false,
      memberInfo: {
        first_name: '',
        last_name: '',
        email: '',
        dob: '',
        id_type: 'MI',
        identification: '',
        uuid: '',
      },
      payerInfo: {
        key: '',
        name: '',
      },
      subscriberInfo: {
        first_name: '',
        last_name: '',
        dob: '',
      },
      eligibilityResponse: {
        eligible: false,
        steps: null,
      },
      showEligibilityResponse: false,
    };
  },
  computed: {
    selectedClient() {
      const clients = this.$store.getters['Core/getClients'];
      return clients.find(c => Number(c.id) === Number(this.selectedClientId));
    },
    hasAllRequiredInfo() {
      const fieldsToCheck = [ 'first_name', 'last_name', 'dob' ];
      const validFields = fieldsToCheck.filter(f => this.memberInfo[f].trim().length > 0);
      return validFields.length === fieldsToCheck.length && this.selectedClientId;
    },
  },
  async beforeMount() {
    try {
      this.loading = true;
      const result = (await this.$store.dispatch('Core/fetchClients'))
        .sort((a, b) => a.display_name.localeCompare(b.display_name));
      if (result.length > 0) {
        this.selectedClientId = this.$route.params.clientId || result[0].id;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async runMemberEligibilityTest() {
      if (!this.hasAllRequiredInfo) {
        return;
      }

      this.showEligibilityResponse = false;
      if (this.isMemberUnderAge()) {
        this.$noty.error('Member is not eligible (under age)');
        return;
      }

      this.testing = true;
      const requestBody = {
        member: this.memberInfo,
        payer: null,
        // payer: {
        //   key: 'test',
        //   name: 'test',
        // },
        subscriber: null,
      };

      if (this.subscriberInfo && this.subscriberInfo.dob !== '') {
        requestBody.subscriber = this.subscriberInfo;
      }

      if (this.payerInfo && this.payerInfo.key !== '' && this.payerInfo.name !== '') {
        requestBody.payer = this.payerInfo;
      }

      try {
        const { data } = await axios.post(
          `v1/clients/${this.selectedClientId}/eligibility/check`,
          requestBody,
        );

        this.eligibilityResponse = data.data;

        this.$noty.success('Member is eligible');
      } catch ({ response }) {
        if (response.status === 406) {
          this.eligibilityResponse = response.data.data;
          this.$noty.error('Member is not eligible');
          return;
        }

        if (!response.data || !response.data.error_description) {
          this.$noty.error('Request failed');
          return;
        }

        const errorDescription = response.data.error_description;

        let errorMsg = 'Request failed:\n';
        Object.keys(errorDescription).forEach(k => {
          errorDescription[k].forEach(msg => {
            errorMsg += `${msg}\n`;
          });
        });

        this.$noty.error(errorMsg);
      } finally {
        this.testing = false;
        this.showEligibilityResponse = true;
      }
    },
    isMemberUnderAge() {
      let doesClientAllowUnderAge = this.selectedClient.configuration
        && this.selectedClient.configuration.toggles
        && this.selectedClient.configuration.toggles.allow_minor_enrollments;


      if (!this.selectedClient.is_bloom && (doesClientAllowUnderAge === undefined || doesClientAllowUnderAge === null)) {
        doesClientAllowUnderAge = clientToggleOptions.find(({ value }) => value === 'allow_minor_enrollments').default;
      }

      const memberDoB = moment(this.memberInfo.dob);
      const memberAgeInYears = moment().diff(memberDoB, 'years');
      if (doesClientAllowUnderAge) {
        return memberAgeInYears < 13;
      }

      return memberAgeInYears < 18;
    },
  },
};
</script>

<style scoped>

</style>
